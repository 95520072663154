var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(_vm.showTopbar())?_c('topbar'):_vm._e(),(_vm.showSidebar())?_c('sidebar'):_vm._e(),(_vm.userIsSuspended)?_c('div',{staticClass:"mb-6"},[_c('alert-suspended')],1):_vm._e()],1),_c('v-main',[_c('v-container',{class:[
        {
          'mt-5 pt-12': _vm.userIsSuspended,
          'px-8': _vm.$vuetify.breakpoint.mdAndUp,
          'px-6': _vm.$vuetify.breakpoint.smAndDown,
        },
      ],attrs:{"fluid":""}},[_c('RouterView',{key:_vm.$route.fullPath})],1),_c('chats')],1),_c('snackbar-flash-message'),_c('dialog-video'),_c('canceled-dialog'),_c('information-to-update'),_c('dialog-alert-whatsapp')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }