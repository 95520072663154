<template>
  <div>
    <div>
      <topbar v-if="showTopbar()"></topbar>
      <sidebar v-if="showSidebar()"></sidebar>
      <div class="mb-6" v-if="userIsSuspended">
        <alert-suspended></alert-suspended>
      </div>
    </div>
    <v-main>
      <v-container
        fluid
        :class="[
          {
            'mt-5 pt-12': userIsSuspended,
            'px-8': $vuetify.breakpoint.mdAndUp,
            'px-6': $vuetify.breakpoint.smAndDown,
          },
        ]"
      >
        <RouterView :key="$route.fullPath" />
      </v-container>
      <chats></chats>
    </v-main>
    <snackbar-flash-message></snackbar-flash-message>
    <dialog-video></dialog-video>
    <canceled-dialog></canceled-dialog>
    <information-to-update />
    <dialog-alert-whatsapp></dialog-alert-whatsapp>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const Topbar = () => import('@src/components/v-header.vue')
const Sidebar = () => import('@src/components/v-sidebar.vue')
const SnackbarFlashMessage = () =>
  import('@src/components/widget/SnackbarFlashMessage.vue')
const chats = () => import('@src/components/chats/chats.vue')
const dialogVideo = () => import('@src/components/dialogs/dialog-video.vue')
const alertSuspended = () => import('./alertSuspended.vue')
const canceledDialog = () => import('./canceled/canceledDialog.vue')
const informationToUpdate = () =>
  import('@src/components/dialogs/dialog-informationToUpdate.vue')

const dialogAlertWhatsapp = () =>
  import('@src/components/dialogs/dialog-alertWhatsapp.vue')

export default {
  name: 'layout-app',
  data() {
    return {}
  },
  methods: {
    showTopbar() {
      return this.$store.state.layout.showTopbar
    },
    showSidebar() {
      return this.$store.state.layout.showSidebar
    },
  },
  components: {
    Topbar,
    Sidebar,
    SnackbarFlashMessage,
    chats,
    dialogVideo,
    alertSuspended,
    canceledDialog,
    informationToUpdate,
    dialogAlertWhatsapp,
  },
  computed: {
    ...mapGetters({ userIsSuspended: 'auth/userIsSuspended' }),
  },
}
</script>
